import React, {Component} from 'react';
import AwesomeModal from 'react-awesome-modal';

export class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        }
    }

    openModal() {
        this.setState({
            visible: true
        });
    }

    closeModal() {
        this.setState({
            visible: false
        });
    }

    render() {
        return (
    <div className="pop-up-modal">
        <AwesomeModal visible={this.state.visible} width="400" effect="fadeInUp" onClickAway={() => this.closeModal()}>
            <div>
                <a href="javascript:void(0);" onClick={() => this.closeModal()}>
                <span>
                    <span className="bm-cross-first"></span>
                    <span className="bm-cross-second"></span>
                </span>
                </a>
                <div className="paragraph-pop-up">
                    {this.props.children}
                </div>
            </div>
        </AwesomeModal>
        <div className="show-more">
            <input type="button" value="Mehr" onClick={() => this.openModal()} />
        </div>
    </div>
        )
    }
}