import React, { Component } from 'react';
import Gaestebuch from '../assets/images/gpic1.JPG';
import VitalityLogo from '../assets/images/In-Balance-Logo.png';
import {PopupGaestebuch} from '../popup/PopupGaestebuch';
import Video from '../assets/videos/Young_Living_Antje_Luedemann.mp4'
import { PopupGalleryPagination } from '../popup/PopupGalleryPagination';

export class InBalanceEvent extends Component {

    render() {
        return (
            <div className="divBackground">
                <div className="section-wrapper" id="section-wrapper-three">
                    <div className="border-wrapper-top">
                        <p className="top-balance">Vitality lifestyle - be yourself in balance</p>
                        <img src={VitalityLogo} alt=""/>
                    </div>
                    <div className="border-wrapper">
                        <span className="border-span" id="top-left"></span>
                        <span className="border-span" id="top-right"></span>
                        <div className="box">
                            <PopupGalleryPagination>
                            </PopupGalleryPagination>
                        </div>
                        <div className="box">
                            <video controls>
                                <source src={Video}/>
                            </video>
                        </div>
                        <div className="box">
                            <PopupGaestebuch>
                                <img src={Gaestebuch} alt="Gaestebuch"/>
                            </PopupGaestebuch>
                        </div>
                        <h2 className="hash-mobile">#YLlifestyleFeb2k19</h2>
                        <span className="border-span" id="bottom-left"></span>
                        <span className="border-span" id="bottom-right"></span>
                    </div>
                    <div className="border-wrapper-bottom">
                        <h2>#YLlifestyleFeb2k19</h2>
                    </div>
                </div>
            </div>
        )
    }
}