import bsImg1 from '../assets/businessImages/bsImg1.jpg'
import bsImg2 from '../assets/businessImages/bsImg2.jpg'
import bsImg3 from '../assets/businessImages/bsImg3.jpg'
import bsImg4 from '../assets/businessImages/bsImg4.jpg'
import bsImg5 from '../assets/businessImages/bsImg5.jpg'
import bsImg6 from '../assets/businessImages/bsImg6.jpg'
import bsImg7 from '../assets/businessImages/bsImg7.jpg'
import bsImg8 from '../assets/businessImages/bsImg8.jpg'
import bsImg9 from '../assets/businessImages/bsImg9.jpg'
import bsImg10 from '../assets/businessImages/bsImg10.jpg'
import bsImg11 from '../assets/businessImages/bsImg11.jpg'
import bsImg12 from '../assets/businessImages/bsImg12.jpg'
import bsImg13 from '../assets/businessImages/bsImg13.jpg'
import bsImg14 from '../assets/businessImages/bsImg14.jpg'
import bsImg15 from '../assets/businessImages/bsImg15.jpg'
import bsImg16 from '../assets/businessImages/bsImg16.jpg'
import bsImg17 from '../assets/businessImages/bsImg17.jpg'
import bsImg18 from '../assets/businessImages/bsImg18.jpg'
import bsImg19 from '../assets/businessImages/bsImg19.jpg'
import bsImg20 from '../assets/businessImages/bsImg20.jpg'
import bsImg21 from '../assets/businessImages/bsImg21.jpg'
import bsImg22 from '../assets/businessImages/bsImg22.jpg'
import bsImg23 from '../assets/businessImages/bsImg23.jpg'
import bsImg24 from '../assets/businessImages/bsImg24.jpg'
import bsImg25 from '../assets/businessImages/bsImg25.jpg'
import bsImg26 from '../assets/businessImages/bsImg26.jpg'
import bsImg27 from '../assets/businessImages/bsImg27.jpg'
import bsImg28 from '../assets/businessImages/bsImg28.jpg'
import bsImg29 from '../assets/businessImages/bsImg29.jpg'
import bsImg30 from '../assets/businessImages/bsImg30.jpg'
import bsImg31 from '../assets/businessImages/bsImg31.jpg'
import bsImg32 from '../assets/businessImages/bsImg32.jpg'
import bsImg33 from '../assets/businessImages/bsImg33.jpg'
import bsImg34 from '../assets/businessImages/bsImg34.jpg'
import bsImg35 from '../assets/businessImages/bsImg35.jpg'
import bsImg36 from '../assets/businessImages/bsImg36.jpg'
import bsImg37 from '../assets/businessImages/bsImg37.jpg'
import bsImg38 from '../assets/businessImages/bsImg38.jpg'
import bsImg39 from '../assets/businessImages/bsImg39.jpg'
import bsImg40 from '../assets/businessImages/bsImg40.jpg'
import bsImg41 from '../assets/businessImages/bsImg41.jpg'
import bsImg42 from '../assets/businessImages/bsImg42.jpg'
import bsImg43 from '../assets/businessImages/bsImg43.jpg'
import bsImg44 from '../assets/businessImages/bsImg44.jpg'
import bsImg45 from '../assets/businessImages/bsImg45.jpg'
import bsImg46 from '../assets/businessImages/bsImg46.jpg'
import bsImg47 from '../assets/businessImages/bsImg47.jpg'
import bsImg48 from '../assets/businessImages/bsImg48.jpg'
import bsImg49 from '../assets/businessImages/bsImg49.jpg'
import bsImg50 from '../assets/businessImages/bsImg50.jpg'
import bsImg51 from '../assets/businessImages/bsImg51.jpg'
import bsImg52 from '../assets/businessImages/bsImg52.jpg'
import bsImg53 from '../assets/businessImages/bsImg53.jpg'
import bsImg54 from '../assets/businessImages/bsImg54.jpg'
import bsImg55 from '../assets/businessImages/bsImg55.jpg'
import bsImg56 from '../assets/businessImages/bsImg56.jpg'
import bsImg57 from '../assets/businessImages/bsImg57.jpg'
import bsImg58 from '../assets/businessImages/bsImg58.jpg'
import bsImg59 from '../assets/businessImages/bsImg59.jpg'
import bsImg60 from '../assets/businessImages/bsImg60.jpg'
import bsImg61 from '../assets/businessImages/bsImg61.jpg'
import bsImg62 from '../assets/businessImages/bsImg62.jpg'
import bsImg63 from '../assets/businessImages/bsImg63.jpg'
import bsImg64 from '../assets/businessImages/bsImg64.jpg'
import bsImg65 from '../assets/businessImages/bsImg65.jpg'
import bsImg66 from '../assets/businessImages/bsImg66.jpg'
import bsImg67 from '../assets/businessImages/bsImg67.jpg'
import bsImg68 from '../assets/businessImages/bsImg68.jpg'
import bsImg69 from '../assets/businessImages/bsImg69.jpg'
import bsImg70 from '../assets/businessImages/bsImg70.jpg'
import bsImg71 from '../assets/businessImages/bsImg71.jpg'
import bsImg72 from '../assets/businessImages/bsImg72.jpg'
import bsImg73 from '../assets/businessImages/bsImg73.jpg'
import bsImg74 from '../assets/businessImages/bsImg74.jpg'
import bsImg75 from '../assets/businessImages/bsImg75.jpg'
import bsImg76 from '../assets/businessImages/bsImg76.jpg'
import bsImg77 from '../assets/businessImages/bsImg77.jpg'
import bsImg78 from '../assets/businessImages/bsImg78.jpg'
import bsImg79 from '../assets/businessImages/bsImg79.jpg'
import bsImg80 from '../assets/businessImages/bsImg80.jpg'
import bsImg81 from '../assets/businessImages/bsImg81.jpg'
import bsImg82 from '../assets/businessImages/bsImg82.jpg'
import bsImg83 from '../assets/businessImages/bsImg83.jpg'
import bsImg84 from '../assets/businessImages/bsImg84.jpg'
import bsImg85 from '../assets/businessImages/bsImg85.jpg'
import bsImg86 from '../assets/businessImages/bsImg86.jpg'
import bsImg87 from '../assets/businessImages/bsImg87.jpg'
import bsImg88 from '../assets/businessImages/bsImg88.jpg'
import bsImg89 from '../assets/businessImages/bsImg89.jpg'
import bsImg90 from '../assets/businessImages/bsImg90.jpg'
import bsImg91 from '../assets/businessImages/bsImg91.jpg'
import bsImg92 from '../assets/businessImages/bsImg92.jpg'
import bsImg93 from '../assets/businessImages/bsImg93.jpg'
import bsImg94 from '../assets/businessImages/bsImg94.jpg'
import bsImg95 from '../assets/businessImages/bsImg95.jpg'
import bsImg96 from '../assets/businessImages/bsImg96.jpg'
import bsImg97 from '../assets/businessImages/bsImg97.jpg'
import bsImg98 from '../assets/businessImages/bsImg98.jpg'
import bsImg99 from '../assets/businessImages/bsImg99.jpg'
import bsImg100 from '../assets/businessImages/bsImg100.jpg'
import bsImg101 from '../assets/businessImages/bsImg101.jpg'
import bsImg102 from '../assets/businessImages/bsImg102.jpg'
import bsImg103 from '../assets/businessImages/bsImg103.jpg'
import bsImg104 from '../assets/businessImages/bsImg104.jpg'
import bsImg105 from '../assets/businessImages/bsImg105.jpg'
import bsImg106 from '../assets/businessImages/bsImg106.jpg'
import bsImg107 from '../assets/businessImages/bsImg107.jpg'
import bsImg108 from '../assets/businessImages/bsImg108.jpg'
import bsImg109 from '../assets/businessImages/bsImg109.jpg'
import bsImg110 from '../assets/businessImages/bsImg110.jpg'
import bsImg111 from '../assets/businessImages/bsImg111.jpg'
import bsImg112 from '../assets/businessImages/bsImg112.jpg'
import bsImg113 from '../assets/businessImages/bsImg113.jpg'
import bsImg114 from '../assets/businessImages/bsImg114.jpg'
import bsImg115 from '../assets/businessImages/bsImg115.jpg'
import bsImg116 from '../assets/businessImages/bsImg116.jpg'
import bsImg117 from '../assets/businessImages/bsImg117.jpg'
import bsImg118 from '../assets/businessImages/bsImg118.jpg'
import bsImg119 from '../assets/businessImages/bsImg119.jpg'
import bsImg120 from '../assets/businessImages/bsImg120.jpg'
import bsImg121 from '../assets/businessImages/bsImg121.jpg'
import bsImg122 from '../assets/businessImages/bsImg122.jpg'
import bsImg123 from '../assets/businessImages/bsImg123.jpg'
import bsImg124 from '../assets/businessImages/bsImg124.jpg'
import bsImg125 from '../assets/businessImages/bsImg125.jpg'
import bsImg126 from '../assets/businessImages/bsImg126.jpg'
import bsImg127 from '../assets/businessImages/bsImg127.jpg'
import bsImg128 from '../assets/businessImages/bsImg128.jpg'
import bsImg129 from '../assets/businessImages/bsImg129.jpg'
import bsImg130 from '../assets/businessImages/bsImg130.jpg'
import bsImg131 from '../assets/businessImages/bsImg131.jpg'
import bsImg132 from '../assets/businessImages/bsImg132.jpg'
import bsImg133 from '../assets/businessImages/bsImg133.jpg'
import bsImg134 from '../assets/businessImages/bsImg134.jpg'
import bsImg135 from '../assets/businessImages/bsImg135.jpg'
import bsImg136 from '../assets/businessImages/bsImg136.jpg'
import bsImg137 from '../assets/businessImages/bsImg137.jpg'
import bsImg138 from '../assets/businessImages/bsImg138.jpg'
import bsImg139 from '../assets/businessImages/bsImg139.jpg'
import bsImg140 from '../assets/businessImages/bsImg140.jpg'
import bsImg141 from '../assets/businessImages/bsImg141.jpg'
import bsImg142 from '../assets/businessImages/bsImg142.jpg'
import bsImg143 from '../assets/businessImages/bsImg143.jpg'
import bsImg144 from '../assets/businessImages/bsImg144.jpg'
import bsImg145 from '../assets/businessImages/bsImg145.jpg'
import bsImg146 from '../assets/businessImages/bsImg146.jpg'
import bsImg147 from '../assets/businessImages/bsImg147.jpg'
import bsImg148 from '../assets/businessImages/bsImg148.jpg'
import bsImg149 from '../assets/businessImages/bsImg149.jpg'
import bsImg150 from '../assets/businessImages/bsImg150.jpg'
import bsImg151 from '../assets/businessImages/bsImg151.jpg'
import bsImg152 from '../assets/businessImages/bsImg152.jpg'
import bsImg153 from '../assets/businessImages/bsImg153.jpg'
import bsImg154 from '../assets/businessImages/bsImg154.jpg'
import bsImg155 from '../assets/businessImages/bsImg155.jpg'
import bsImg156 from '../assets/businessImages/bsImg156.jpg'
import bsImg157 from '../assets/businessImages/bsImg157.jpg'


export const getImages = () => {
    return [
        bsImg1,
        bsImg2,
        bsImg3,
        bsImg4,
        bsImg5,
        bsImg6,
        bsImg7,
        bsImg8,
        bsImg9,
        bsImg10,
        bsImg11,
        bsImg12,
        bsImg13,
        bsImg14,
        bsImg15,
        bsImg16,
        bsImg17,
        bsImg18,
        bsImg19,
        bsImg20,
        bsImg21,
        bsImg22,
        bsImg23,
        bsImg24,
        bsImg25,
        bsImg26,
        bsImg27,
        bsImg28,
        bsImg29,
        bsImg30,
        bsImg31,
        bsImg32,
        bsImg33,
        bsImg34,
        bsImg35,
        bsImg36,
        bsImg37,
        bsImg38,
        bsImg39,
        bsImg40,
        bsImg41,
        bsImg42,
        bsImg43,
        bsImg44,
        bsImg45,
        bsImg46,
        bsImg47,
        bsImg48,
        bsImg49,
        bsImg50,
        bsImg51,
        bsImg52,
        bsImg53,
        bsImg54,
        bsImg55,
        bsImg56,
        bsImg57,
        bsImg58,
        bsImg59,
        bsImg60,
        bsImg61,
        bsImg62,
        bsImg63,
        bsImg64,
        bsImg65,
        bsImg66,
        bsImg67,
        bsImg68,
        bsImg69,
        bsImg70,
        bsImg71,
        bsImg72,
        bsImg73,
        bsImg74,
        bsImg75,
        bsImg76,
        bsImg77,
        bsImg78,
        bsImg79,
        bsImg80,
        bsImg81,
        bsImg82,
        bsImg83,
        bsImg84,
        bsImg85,
        bsImg86,
        bsImg87,
        bsImg88,
        bsImg89,
        bsImg90,
        bsImg91,
        bsImg92,
        bsImg93,
        bsImg94,
        bsImg95,
        bsImg96,
        bsImg97,
        bsImg98,
        bsImg99,
        bsImg100,
        bsImg101,
        bsImg102,
        bsImg103,
        bsImg104,
        bsImg105,
        bsImg106,
        bsImg107,
        bsImg108,
        bsImg109,
        bsImg110,
        bsImg111,
        bsImg112,
        bsImg113,
        bsImg114,
        bsImg115,
        bsImg116,
        bsImg117,
        bsImg118,
        bsImg119,
        bsImg120,
        bsImg121,
        bsImg122,
        bsImg123,
        bsImg124,
        bsImg125,
        bsImg126,
        bsImg127,
        bsImg128,
        bsImg129,
        bsImg130,
        bsImg131,
        bsImg132,
        bsImg133,
        bsImg134,
        bsImg135,
        bsImg136,
        bsImg137,
        bsImg138,
        bsImg139,
        bsImg140,
        bsImg141,
        bsImg142,
        bsImg143,
        bsImg144,
        bsImg145,
        bsImg146,
        bsImg147,
        bsImg148,
        bsImg149,
        bsImg150,
        bsImg151,
        bsImg152,
        bsImg153,
        bsImg154,
        bsImg155,
        bsImg156,
        bsImg157,
    ]
};