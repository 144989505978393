import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Facebook from '../assets/images/Facebook.png';
import Instagram from '../assets/images/Instagram.png';
import Logo from '../assets/images/Logo_Rose_weiss.png';

export class Footer extends Component {

    render() {
        return (
            <div className="section-wrapper-footer">
                <div className="footer-mobile">
                    <div className="social-media">
                        <a href="https://www.facebook.com/%C3%84therische-%C3%96le-Wellness-Co-Infos-von-Antje-L%C3%BCdemann-643839085707310/?eid=ARDl7ZuS2e1z-OkpcE8Myl4EXwxUHirfmWJALWY_I685VA-MtfL6RgrC_i8lawh1IyvcqT29bjpz1lxZ&fref=tag" className="facebook">
                            <img src={Facebook} alt="Facebook" />
                        </a>
                        <a>
                            <img src={Logo} alt="Logo"/>
                        </a>
                        <a href='https://www.instagram.com/antje_luedemann/?hl=de' className="instagram">
                            <img src={Instagram} alt="Instagram"/>
                        </a>
                    </div>
                    <div className="footer-content">
                        <Link to="/impressum" >Impressum – legal notice</Link>
                        <Link to="/datenschutz">Datenschutz – privacy notice</Link>
                        <Link to="/disclaimer">Disclaimer</Link>
                        <Link to="/partner">Meine Partner</Link>
                    </div>
                    <div className="footer-content">
                        <span>Ätherische Öle, Lifestyle & Co.</span>
                        <span>Antje Lüdemann</span>
                    </div>
                    <div className="footer-content">
                        <span>info@antje-luedemann.de</span>
                    </div>
                </div>
                <div className="footer-desktop">
                    <div className="div-content">
                        <a>
                            <img src={Logo} alt="Logo"/>
                        </a>
                    </div>
                    <div className="footer-content" id="footer-adress">
                        <span>Ätherische Öle, Lifestyle & Co.</span>
                        <span>Antje Lüdemann</span>
                        <div className="contact">
                            <span>info@antje-luedemann.de</span>
                        </div>
                    </div>
                    <div className="footer-content">
                        <Link to="/impressum" >Impressum – legal notice</Link>
                        <Link to="/datenschutz">Datenschutz– privacy notice</Link>
                        <Link to="/disclaimer">Disclaimer</Link>
                        <Link to="/partner">Meine Partner</Link>
                        <div className="social-media">
                            <a href='https://www.facebook.com/%C3%84therische-%C3%96le-Wellness-Co-Infos-von-Antje-L%C3%BCdemann-643839085707310/?eid=ARDl7ZuS2e1z-OkpcE8Myl4EXwxUHirfmWJALWY_I685VA-MtfL6RgrC_i8lawh1IyvcqT29bjpz1lxZ&fref=tag' className="facebook">
                                <img src={Facebook} alt="facebook"/>
                            </a>
                            <a href='https://www.instagram.com/antje_luedemann/?hl=de' className="instagramm">
                                <img src={Instagram} alt="instagram"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}