import React, { Component } from 'react';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';
import {
    BrowserRouter as Router,
    Route
} from 'react-router-dom'
import {AboutMe, Team, Event, InBalanceEvent, BusinessEvent, SaveTheDate, Speaker, HarmonyEvent} from './pages';
import { Footer } from './footer/Footer';
import { Impressum } from './impressum/Impressum';
import {Datenschutz} from './datenschutz/Datenschutz';
import {Disclaimer} from './disclaimer/Disclaimer';
import {Partner} from './partner/Partner';
import { MobileMenu } from './header/MobileMenu';
import { NavBar} from './header/NavBar';
import {NewsLetter} from './header/NewsLetter';
import ScrollToTop from './util/ScrollToTop';

configureAnchors({
    offset: -100
});



export default class App extends Component {

    home = () => (
            <React.Fragment>
                <div className="page">
                    <div className="header">
                        <MobileMenu />
                        <NavBar/>
                        <NewsLetter/>
                    </div>
                    <div className="content-wrapper">
                <ScrollableAnchor id="about-me">
                    <div className="section">
                        <AboutMe />
                    </div>
                </ScrollableAnchor>
                <ScrollableAnchor id="team">
                    <div className="section">
                        <Team />
                    </div>
                </ScrollableAnchor>
                <ScrollableAnchor id="event chrome">
                    <div className="section chrome" id="section-three">
                        <Event />
                    </div>
                </ScrollableAnchor>
                <ScrollableAnchor id="event safari">
                    <div className="section safari" id="section-three">
                        <InBalanceEvent />
                    </div>
                </ScrollableAnchor>
                <div className="section section-seperation safari"></div>
                <ScrollableAnchor id="eventBusiness safari">
                    <div className="section safari" id="section-three">
                        <BusinessEvent />
                    </div>
                </ScrollableAnchor>
                <div className="section section-seperation safari"></div>
                <ScrollableAnchor id="eventHarmony safari">
                    <div className="section safari" id="section-three">
                        <HarmonyEvent />
                    </div>
                </ScrollableAnchor>
                <ScrollableAnchor id="save-the-date">
                    <div className="section">
                        <h2 className="save-the-date">Save the date!</h2>
                        <SaveTheDate />
                        <div className="button-ticket">
                            <button className="ticket"><span className="ticket-mobile"><a href="https://www.eventbrite.de/o/antje-ludemann-15709221147">Dein Ticket!</a></span><span className="ticket-desktop"><a href="https://www.eventbrite.de/o/antje-ludemann-15709221147">Sichere dir dein Ticket hier!</a></span></button>
                        </div>
                    </div>
                </ScrollableAnchor>
                <ScrollableAnchor id="speaker">
                    <div className="section-speaker">
                        <Speaker />
                    </div>
                </ScrollableAnchor>
                <ScrollableAnchor id="footer">
                    <div className="section-footer">
                        <Footer />
                    </div>
                </ScrollableAnchor>
                        </div>
                </div>
            </React.Fragment>
    )

    impressum = () => (
        <div className="Impressum">
            <Impressum />
        </div>
    )

    datenschutz = () => (
        <div className="Datenschutz">
            <Datenschutz />
        </div>
    )

    disclaimer = () => (
        <div className="Disclaimer">
            <Disclaimer />
        </div>
    )

    partner = () => (
        <div className="Partner">
            <Partner />
        </div>
    )

    render() {
        return (
            <Router>
                <ScrollToTop>
                <div>
                    <Route exact path="/" component={this.home} />
                    <Route path="/impressum" component={this.impressum} />
                    <Route path="/datenschutz" component={this.datenschutz} />
                    <Route path="/disclaimer" component={this.disclaimer} />
                    <Route path="/partner" component={this.partner} />
                </div>
                </ScrollToTop>
            </Router>

        )
    }
}