import React, { Component } from 'react'

import ReactBnbGallery from 'react-bnb-gallery'
import {getImages} from '../util/businessImage';
import Gallerie from  '../assets/businessImages/bsImg48.jpg'

const photos = getImages();

export class PopupBusinessGallery extends Component {
    constructor() {
        super(...arguments);
        this.state = { galleryOpened: false };
        this.toggleGallery = this.toggleGallery.bind(this);
    }

    toggleGallery() {
        this.setState(prevState => ({
            galleryOpened: !prevState.galleryOpened
        }));
    }

    render () {
        return (
            <div>
                <img onClick={this.toggleGallery} src={Gallerie} alt="gallery"/>
                <ReactBnbGallery show={this.state.galleryOpened} photos={photos} onClose={this.toggleGallery} />
            </div>
        )
    }
}