import React, { Component } from 'react';
import VitalityLogoBusinessBaum from '../assets/images/passionizeyourbusinessbaum.png';
import { PopupBusinessGallery } from "../popup/PopupBusinessGallery";
import BusinessLogo from "../assets/images/passionizeyourbusiness.png";


export class BusinessEvent extends Component {

    render() {
        return (
            <div className="divBackground">
                <div className="section-wrapper" id="section-wrapper-three">
                    <div className="border-wrapper-top">
                        <p className="top-business">Vitality lifestyle - passionize your business</p>
                        <img src={VitalityLogoBusinessBaum} alt="logo"/>
                    </div>
                    <div className="border-wrapper" id="business">
                        <span className="border-span" id="top-left"></span>
                        <span className="border-span" id="top-right"></span>
                        <div className="box-second">
                            <img src={BusinessLogo} alt="logo"/>
                        </div>
                        <div className="box-second" id="box-second-Business">
                            <PopupBusinessGallery>
                            </PopupBusinessGallery>
                        </div>
                        <h2 className="hash-mobile">for powerful solution</h2>
                        <span className="border-span" id="bottom-left"></span>
                        <span className="border-span" id="bottom-right"></span>
                    </div>
                    <div className="border-wrapper-bottom">
                        <h2>for powerful solution</h2>
                    </div>

                </div>
            </div>
        )
    }
}