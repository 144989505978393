import React, { Component } from 'react';
import {Modal} from '../popup/Modal';
import Eventteam from '../assets/images/Eventteam.jpg';

export class Team extends Component {

    render() {
        return (
            <div className="section-wrapper">
                <h2>Das Team</h2>
                <img className="eventteam" src={Eventteam} alt="Eventteam"/>
                <p className="meinTeam-paragraph">
                    Dustin Maisch (IT & Web-Administration) | Sonja Lüdemann (Grafik & Animationsdesign) | Antje Lüdemann (Geschäftsführerin) |<br /> Axel Zitzmann (Finanzen, Medien & PR) | Maike Lüdemann (Branding, Marketing & Screen Design) | Ingrid Beintner (Design & Co Werbepartner)
                </p>
                <Modal>
                    <p>
                        Dustin Maisch (IT & Web-Administration) <br /><br /> Sonja Lüdemann (Grafik & Animationsdesign) <br /><br /> Antje Lüdemann (Geschäftsführerin) <br /><br /> Axel Zitzmann (Finanzen, Medien & PR) <br /><br /> Maike Lüdemann (Grafik, Marketing & Screen Design) <br /><br /> Ingrid Beintner (Design & Co Werbepartner)
                    </p>
                </Modal>
            </div>
        )
    }
}