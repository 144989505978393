import Gpic1 from '../assets/images/gpic1.JPG';
import Gpic2 from '../assets/images/gpic2.JPG';
import Gpic3 from '../assets/images/gpic3.JPG';
import Gpic4 from '../assets/images/gpic4.JPG';
import Gpic5 from '../assets/images/gpic5.JPG';
import Gpic6 from '../assets/images/gpic6.JPG';
import Gpic7 from '../assets/images/gpic7.JPG';
import Gpic8 from '../assets/images/gpic8.JPG';
import Gpic9 from '../assets/images/gpic9.JPG';
import Gpic10 from '../assets/images/gpic10.JPG';
import Gpic11 from '../assets/images/gpic11.JPG';
import Gpic12 from '../assets/images/gpic12.JPG';
import Gpic13 from '../assets/images/gpic13.JPG';
import Gpic14 from '../assets/images/gpic14.JPG';
import Gpic15 from '../assets/images/gpic15.JPG';
import Gpic16 from '../assets/images/gpic16.JPG';
import Gpic17 from '../assets/images/gpic17.JPG';
import Gpic18 from '../assets/images/gpic18.JPG';
import Gpic19 from '../assets/images/gpic19.JPG';
import Gpic20 from '../assets/images/gpic20.JPG';
import Gpic21 from '../assets/images/gpic21.JPG';

export const getImages = () => {
    return [
        Gpic1,
        Gpic2,
        Gpic3,
        Gpic4,
        Gpic5,
        Gpic6,
        Gpic7,
        Gpic8,
        Gpic9,
        Gpic10,
        Gpic11,
        Gpic12,
        Gpic13,
        Gpic14,
        Gpic15,
        Gpic16,
        Gpic17,
        Gpic18,
        Gpic19,
        Gpic20,
        Gpic21,
    ]
}