import React, {Component} from 'react';
import { Link } from 'react-router-dom';

export class Partner extends Component {


    render() {
        return (
            <div className="section-wrapper">
                <div className="partner">
                    <h1>Partner</h1>

                        <div className="partner-content">
                            <div className="partner-adress">
                                <span><strong>media + business - solutions</strong></span>
                                <span>Axel Zitzmann</span>
                                <span>info@mbsolutions.de</span>
                                <span>+491607716560</span>
                            </div>
                            <div className="partner-adress">
                                <span><strong>IT & Web-Administration</strong></span>
                                <span>Dustin Maisch</span>
                                <span>dustin-maisch@hotmail.de</span>
                                <span>+4917670898509</span>
                            </div>
                        </div>


                        <div className="partner-content">
                            <div className="partner-adress">
                                <span><strong>Design & Co Werbepartner</strong></span>
                                <span>Ingrid Beintner</span>
                                <span>i.beintner@design-co.info</span>
                                <span>+4941837742020</span>
                            </div>
                            <div className="partner-adress">
                                <span><strong>Branding, Marketing & Screen Design</strong></span>
                                <span>Maike Lüdemann</span>
                                <span>maike.luedemann1@gmail.com</span>
                                <span>+4917670898509</span>
                            </div>
                        </div>


                        <div className="partner-content">
                            <div className="partner-adress">
                                <span><strong>Grafik & Animationsdesign</strong></span>
                                <span>Sonja Lüdemann</span>
                                <span>sonjaluedemann97@gmail.com</span>
                            </div>
                        </div>

                    <button className="partner-button">
                        <Link to="/" >Zurück</Link>
                    </button>
                </div>
            </div>
        )
    }
}