import React from 'react';
import {slide as Burger} from 'react-burger-menu';

export class MobileMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    closeMenu = (e) => {
        console.log('wtf?');
        this.setState({
            isOpen: false
        })
    }

    render() {
       return (
           <Burger isOpen={this.state.isOpen} >
               <a  href="#about-me" className="menu-item">Home</a>
               <a  href="#team" className="menu-item">Team</a>
               <a  href="#event" className="menu-item">Event</a>
               <a  href="#save-the-date" className="menu-item">Save the Date</a>
               <a  href="#footer" className="menu-item">Sonstiges</a>
           </Burger>
       )
   }
}

