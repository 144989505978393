import React, {Component} from 'react';
import {Modal} from '../popup/Modal';
import Businessfoto from '../assets/images/Businessfoto.jpg';

export class AboutMe extends Component {


    render() {
        return (
            <div className="section-wrapper" id="section-about-me">
                <img className="businessfoto" src={Businessfoto} alt="dasTeam"/>
                <div className="about-me">
                    <h2>„Vitalität als Lifestyle lässt uns verstehen, welche Kraft und Liebe jeder Einzelne von uns hat. So gestalten wir die Zukunft und verändern die Welt.“</h2>
                    <p>Ich freue mich, Ihnen unsere neue Veranstaltungsreihe zum Thema<br /><br />

                        “Gesundheit, gesunder Lifestyle und alles, was dazu gehört“ in der Lüneburger Heide vorzustellen.<br /><br />

                        In unseren Veranstaltungen bieten wir Ihnen eine Vielzahl kurzweilig vorgestellter Informationen in einem wunderschönen Ambiente und eine unterhaltsame Abendveranstaltung zum sich Kennenlernen und Austauschen.<br /><br />

                        „Be Yourself - Vitality Lifestyle in Balance“ ist der Auftakt in diesem Jahr und bewusst in den Februar gelegt, so dass eine gesunde Erholungsphase nach Weihnachten und dem Jahreswechsel unseren natürlichen, seit Urzeiten physiologischen Rhythmus unterstützt. Der Februar ist ein wunderbarer Zeitpunkt, um unseren Körper und unsere Aktivitäten für das Erwachen der Natur vorzubereiten und voller Kraft und Freude in den Frühling zu starten.
                    </p>
                </div>
                <Modal>
                    <p>Ich freue mich, Ihnen unsere neue Veranstaltungsreihe zum Thema<br /><br />

                        “Gesundheit, gesunder Lifestyle und alles, was dazu gehört“ in der Lüneburger Heide vorzustellen.<br /><br />

                        In unseren Veranstaltungen bieten wir Ihnen eine Vielzahl kurzweilig vorgestellter Informationen in einem wunderschönen Ambiente und eine unterhaltsame Abendveranstaltung zum sich Kennenlernen und Austauschen.<br /><br />

                        „Be Yourself - Vitality Lifestyle in Balance“ ist der Auftakt in diesem Jahr und bewusst in den Februar gelegt, so dass eine gesunde Erholungsphase nach Weihnachten und dem Jahreswechsel unseren natürlichen, seit Urzeiten physiologischen Rhythmus unterstützt. Der Februar ist ein wunderbarer Zeitpunkt, um unseren Körper und unsere Aktivitäten für das Erwachen der Natur vorzubereiten und voller Kraft und Freude in den Frühling zu starten.
                    </p>
                </Modal>
            </div>
        )
    }
}