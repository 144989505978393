import React, { Component } from 'react';
import Gaestebuch from '../assets/images/gpic1.JPG';
import {PopupGaestebuch} from '../popup/PopupGaestebuch';
import HerbstLogo from "../assets/images/Herbst-eventlogo.png";
import {PopupInHarmonyGallery} from "../popup/PopupInHarmonyGallery";
import VideoHerbst from "../assets/videos/TrailerVitalityLifestyleInHarmony.mp4";

export class HarmonyEvent extends Component {

    render() {
        return (
            <div className="divBackground">
                <div className="section-wrapper" id="section-wrapper-three">
                    <div className="border-wrapper-top">
                        <p className="top-harmony">Vitality lifestyle - in harmony</p>
                        <img className="harmony-img" src={HerbstLogo} alt=""/>
                    </div>
                    <div className="border-wrapper border-harmony">
                        <span className="border-span" id="top-left"></span>
                        <span className="border-span" id="top-right"></span>
                        <div className="box">
                            <PopupInHarmonyGallery>
                            </PopupInHarmonyGallery>
                        </div>
                        <div className="box">
                            <video controls>
                                <source src={VideoHerbst}/>
                            </video>
                        </div>
                        <div className="box">
                            <PopupGaestebuch>
                                <img src={Gaestebuch} alt="Gaestebuch"/>
                            </PopupGaestebuch>
                        </div>
                        <h2 className="hash-mobile">#YLlifestyleNov2k19</h2>
                        <span className="border-span" id="bottom-left"></span>
                        <span className="border-span" id="bottom-right"></span>
                    </div>
                    <div className="border-wrapper-bottom">
                        <h2>#YLlifestyleNov2k19</h2>
                    </div>
                </div>
            </div>
        )
    }
}