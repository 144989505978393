import React, { Component } from 'react';

export class Speaker extends Component {

    render() {
        return (
            <div className="section-wrapper">
                <div className="speakercontent">
                    <div className="turnaround">
                        <div className="front">

                        </div>
                        <div className="back">

                        </div>
                    </div>
                </div>
                <div className="speakercontent">

                </div>
                <div className="speakercontent">

                </div>
            </div>
        )

    }
}