import React from 'react';
import HeaderImage from '../assets/images/In-Balance-Logo.png'
export const NavBar = (props) => (
    <div className="nav-bar">
        <div className="nav-bar-background-filler" />
            <div className="link-wrapper"><a  href="#about-me" className="link-one">Home</a></div>
            <div className="link-wrapper"><a  href="#team" className="">Team</a></div>
            <div className="link-wrapper" id="link-third"><a  href="#event" className="link-third">Event</a></div>
            <div className="link-wrapper"><a  href="#save-the-date" className="">Save the Date</a></div>
            <div className="link-wrapper"><a  href="#footer" className="">Sonstiges</a></div>
        <img src={HeaderImage}  alt="Logo"/>
    </div>
)