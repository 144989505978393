import React, { Component } from 'react';
import Logo from '../assets/images/passionizeyourbusiness.png';
import {Modal} from '../popup/Modal';

export class SaveTheDate extends Component {

    render() {
        return (
            <div className="section-wrapper" id="section-wrapper-fourth">
                <div className="events-information">
                    <h3>Vitality lifestyle - passionize your business for powerful solutions 2020</h3>
                    <p>
                        Kennst Du das auch? Du kommst aus einem TOP motivierenden Event, weißt gar nicht wohin mit Deiner Energie und den vielen Ideen, kennst alle Deine Warums? Überlegst, wie Du alles sortierst. Ständig motiviert Dich das Wissen, das Du gerade gelernt hast, und Du brichst entschlossen aus Deiner Kommvorzone (Du wirst sehen, wird genau so geschrieben) auf, stürmst auf Deine Tür zu... und schon beim ersten Schritt auf die Straße beschleicht Dich dieses mulmige Gefühl, was wenn... Und wenn doch, wie geht es dann weiter mit Business, Administration & Co., wie wächst und gedeiht "mein" Unternehmen ganz konkret? So oft habe ich genau das auf meinen jüngsten Touren gehört...<br /><br />

                        Deshalb habe ich dieses Business-Training, das sich deutlich von anderen Leadershiptrainings unterscheidet und diese unterstützen und ergänzen soll, gestartet. Alles, was Du bislang gelernt hast, was Dich motiviert, ist wunderbar. Von uns bekommst Du nun dazu eine ganz konkrete, einfache und duplizierbare Anleitung, wie du es perfekt umsetzen kannst. Wir gehen dabei gemeinsam den gesamten Weg von der Einschreibung bis hin zur korrekten Unternehmensgründung und -führung. Power is nothing without control. Möchtest Du mehr dazu erfahren? Dann besuch uns auf unserer Eventseite…<br /><br />

                        Eure Antje
                    </p>
                </div>
                <div className="Information-wrapper">
                    <img src={Logo} alt="logo"/>
                    <ul>
                        <li>konkret</li>
                        <li>einfach</li>
                        <li>duplizierbar</li>
                        <li>umsetzbar</li>
                        <li>wir freuen uns auf euch!</li>
                    </ul>
                    <Modal>
                        <h3>Vitality lifestyle - passionize your business for powerful solutions 2020</h3>
                        <p>
                            Kennst Du das auch? Du kommst aus einem TOP motivierenden Event, weißt gar nicht wohin mit Deiner Energie und den vielen Ideen, kennst alle Deine Warums? Überlegst, wie Du alles sortierst. Ständig motiviert Dich das Wissen, das Du gerade gelernt hast, und Du brichst entschlossen aus Deiner Kommvorzone (Du wirst sehen, wird genau so geschrieben) auf, stürmst auf Deine Tür zu... und schon beim ersten Schritt auf die Straße beschleicht Dich dieses mulmige Gefühl, was wenn... Und wenn doch, wie geht es dann weiter mit Business, Administration & Co., wie wächst und gedeiht "mein" Unternehmen ganz konkret? So oft habe ich genau das auf meinen jüngsten Touren gehört...<br /><br />

                            Deshalb habe ich dieses Business-Training, das sich deutlich von anderen Leadershiptrainings unterscheidet und diese unterstützen und ergänzen soll, gestartet. Alles, was Du bislang gelernt hast, was Dich motiviert, ist wunderbar. Von uns bekommst Du nun dazu eine ganz konkrete, einfache und duplizierbare Anleitung, wie du es perfekt umsetzen kannst. Wir gehen dabei gemeinsam den gesamten Weg von der Einschreibung bis hin zur korrekten Unternehmensgründung und -führung. Power is nothing without control. Möchtest Du mehr dazu erfahren? Dann besuch uns auf unserer Eventseite…<br /><br />

                            Eure Antje
                        </p>
                    </Modal>
                </div>
            </div>
        )
    }
}