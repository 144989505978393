import React, { Component } from 'react';
import Gaestebuch from '../assets/images/gpic1.JPG';
import VitalityLogo from '../assets/images/In-Balance-Logo.png';
import VitalityLogoBusinessBaum from '../assets/images/passionizeyourbusinessbaum.png';
import {PopupGaestebuch} from '../popup/PopupGaestebuch';
import Video from '../assets/videos/Young_Living_Antje_Luedemann.mp4';
import VideoHerbst from '../assets/videos/TrailerVitalityLifestyleInHarmony.mp4';
import { PopupGalleryPagination } from '../popup/PopupGalleryPagination';
import { PopupBusinessGallery } from "../popup/PopupBusinessGallery";
import { PopupInHarmonyGallery } from "../popup/PopupInHarmonyGallery";
import BusinessLogo from "../assets/images/passionizeyourbusiness.png";
import HerbstLogo from '../assets/images/Herbst-eventlogo.png';
import Swiper from 'react-id-swiper';
import 'react-id-swiper/lib/styles/scss/swiper.scss';


export class Event extends Component {

    render() {

        const params = {
            effect: 'cube',
            grabCursor: true,
            // size: 150,
            // swiperSize: 150,
            // rebuildOnUpdate: true,
            cubeEffect: {
                shadow: true,
                slideShadows: true,
                shadowOffset: 50,
                shadowScale: 0.94,
            },
            // speed:1000,
            // autoplay: {
            //     delay: 3000,
            //     disableOnInteraction: false,
            // },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }
        };

        return (
            <div>
                <Swiper {...params}>
                    <div className="swiper-slide">
                        <div className="section-wrapper" id="section-wrapper-three">
                            <div className="border-wrapper-top">
                                <p>Vitality lifestyle - be yourself in balance</p>
                                <img src={VitalityLogo} alt=""/>
                            </div>
                            <div className="border-wrapper">
                                <span className="border-span" id="top-left"></span>
                                <span className="border-span" id="top-right"></span>
                                <div className="box">
                                    <PopupGalleryPagination>
                                    </PopupGalleryPagination>
                                </div>
                                <div className="box">
                                    <video controls>
                                        <source src={Video}/>
                                    </video>
                                </div>
                                <div className="box">
                                    <PopupGaestebuch>
                                        <img src={Gaestebuch} alt="Gaestebuch"/>
                                    </PopupGaestebuch>
                                </div>
                                <h2 className="hash-mobile">#YLlifestyleFeb2k19</h2>
                                <span className="border-span" id="bottom-left"></span>
                                <span className="border-span" id="bottom-right"></span>
                            </div>
                            <div className="border-wrapper-bottom">
                                <h2>#YLlifestyleFeb2k19</h2>
                            </div>

                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="section-wrapper" id="section-wrapper-three">
                            <div className="border-wrapper-top">
                                <p>Vitality lifestyle - passionize your business</p>
                                <img src={VitalityLogoBusinessBaum} alt="logo"/>
                            </div>
                            <div className="border-wrapper" id="business">
                                <span className="border-span" id="top-left"></span>
                                <span className="border-span" id="top-right"></span>
                                <div className="box-second">
                                    <img src={BusinessLogo} alt="logo"/>
                                </div>
                                <div className="box-second" id="box-second-Business">
                                    <PopupBusinessGallery>
                                    </PopupBusinessGallery>
                                </div>
                                <h2 className="hash-mobile">for powerful solution</h2>
                                <span className="border-span" id="bottom-left"></span>
                                <span className="border-span" id="bottom-right"></span>
                            </div>
                            <div className="border-wrapper-bottom">
                                <h2>for powerful solution</h2>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="section-wrapper" id="section-wrapper-three">
                            <div className="border-wrapper-top">
                                <p>Vitality lifestyle - in harmony</p>
                                <img src={HerbstLogo} alt=""/>
                            </div>
                            <div className="border-wrapper border-harmony">
                                <span className="border-span" id="top-left"></span>
                                <span className="border-span" id="top-right"></span>
                                <div className="box">
                                    <PopupInHarmonyGallery>
                                    </PopupInHarmonyGallery>
                                </div>
                                <div className="box">
                                    <video controls>
                                        <source src={VideoHerbst}/>
                                    </video>
                                </div>
                                <div className="box">
                                    <img src={Gaestebuch} alt="Gaestebuch"/>
                                </div>
                                <h2 className="hash-mobile">#YLlifestyleNov2k19</h2>
                                <span className="border-span" id="bottom-left"></span>
                                <span className="border-span" id="bottom-right"></span>
                            </div>
                            <div className="border-wrapper-bottom">
                                <h2>#YLlifestyleNov2k19</h2>
                            </div>

                        </div>
                    </div>
                </Swiper>
            </div>
        )
    }
}